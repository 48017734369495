//import logo from './logo.svg';
import React, {useState} from 'react';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import { Button, Alert, Form } from 'react-bootstrap';

function App() {

  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);
  const [ground, setGround] = useState('Pflaster');
  const [facade, setFacade] = useState('Klinker');
  const [time, setTime] = useState(1);
  const [price, setPrice] = useState(0);

  function calculatePrice(val) {
    
    switch(val.target.id){
      case 'width':
        setWidth(val.target.value);
        break;
      case 'height':
        setHeight(val.target.value);
        break;
      case 'facade':
        setFacade(val.target.value);
        break;
      case 'ground':
        setGround(val.target.value);
        break;
      case 'time':
        setTime(val.target.value);
        break;
    }

    var widthPrice = width * 39;
    var neededLevels = 1 + Math.ceil(height/2.25);

    var calculatedPrice = widthPrice * neededLevels * time; 
    setPrice(calculatedPrice);

  }
  

  return (
    <div className="App">
      <div className="container">
        <div className="row">
          
          <div className="col-12 col-md-6">
            <div className="pt-5 pb-4">
              <p className="h4 font-weight-bolder">Jetzt das eigene Gerüst kalkulieren</p>
              <hr/>

              <Form>
                <Form.Group>
                  <Form.Control id="width" onChange={calculatePrice} type="number" placeholder="Breite"></Form.Control>
                </Form.Group>
                
                <Form.Group>
                  <Form.Control id="height" onChange={calculatePrice} type="number" placeholder="Höhe"></Form.Control>
                </Form.Group>
                
                <Form.Group>
                  <Form.Control id="facade" onChange={calculatePrice} as="select">
                    <option>Klinker</option>
                    <option>Backstein</option>
                    <option>Beton</option>
                    <option>Kalk-Sandstein</option>
                    <option>Bimsstein</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Control id="ground" onChange={calculatePrice} as="select">
                    <option>Pflaster</option>
                    <option>Unbefestigt (Sand, Rasen, etc.)</option>
                    <option>Asphalt oder Teer</option>
                    <option>Verdichtete Erde</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Control id="time" onChange={calculatePrice} type="number" placeholder="Zeitraum"></Form.Control>
                </Form.Group>
              </Form>

              

            </div>  
          </div>

          <div className="col-12 col-md-6">
            <div className="p-4 p-md-5 bg-dark text-white">
              <p className="h3 font-weight-bolder">Ihr Preis</p>
              
              <p>Die Kosten für Ihr Gerüst mit den Eckdaten ...</p>
              
              <table className="table table-borderless table-sm text-white">
                <tbody>
                  <tr>
                    <td className="font-weight-bold pl-0">Breite</td>
                    <td>{width} Meter</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold pl-0">Höhe</td>
                    <td>{height} Meter</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold pl-0">Fassade</td>
                    <td>{facade}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold pl-0">Untergrund</td>
                    <td>{ground}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold pl-0">Zeitraum</td>
                    <td>{time} Tage</td>
                  </tr>
                </tbody>
              </table>

              <p className="h1 pt-3 mt-4 border-top border-light font-weight-bold text-right">€ {price}</p>
              <p className="mb-0 small text-white-50 text-right">zzgl.  19% Mehrwertsteuer</p>
            
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default App;
